<template>
    <div class="wrap">
        <button @click="$refs.carousel.goToPrev()" class="prev-btn">
            <img width="30" src="@/assets/icons/left.svg" alt="">
        </button>
        <slide class="slide-wrap" :options="agileOptions" ref="carousel">
            <div
                class=""
                v-for="(item, index) in data"
                :key="index"
            >
                <div class="slide">
                    <img v-if="item.img_url" :src="item.img_url" :alt="item.name">
                </div>
            </div>
        </slide>
        <button @click="$refs.carousel.goToNext()" class="next-btn">
            <img width="30" src="@/assets/icons/right.svg" alt="">
        </button>
    </div>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
    components: {
        slide: VueAgile
    },
    data() {
        return {
            agileOptions: {
                navButtons: false,
                dots: false,
                swipeDistance: 500,
                initialSlide: 1,
                autoplaySpeed: 5000,
                autoplay: true,
                slidesToShow: 3,
                responsive: [
                    {
                        breakpoint: 374,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 5
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 6
                        }
                    },
                    {
                        breakpoint: 700,
                        settings: {
                            slidesToShow: 7
                        }
                    },
                ]
            },
            sponsorList: [
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
                // require('@/assets/images/mock-logo.png'),
            ]
        }
    },
}
</script>

<style scoped>
.wrap {
    position: relative;
}
.slide-wrap {
    padding: 0 30px;
}
.slide {
    width: 56px !important;
    height: 56px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #002F87;
    /* margin-right: 8px; */
    overflow: hidden;
    /* padding: 6px; */
    margin: 0 auto;
}
.slide img {
    width: 100%;
    object-fit: cover;
}
.prev-btn,
.next-btn {
    width: 24px;
    height: 24px;
    background-color: #FF6B00;
    border-radius: 4px;
    border: 1px solid #FF6B00;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.prev-btn {
    position: absolute;
    left: 0;
    top: 16px;
}
.next-btn {
    position: absolute;
    right: 0;
    top: 16px;
}
</style>