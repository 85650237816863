import Vue from 'vue'
import VueCookies from 'vue-cookies'
import queryString from 'query-string'
import VueNumber from 'vue-number-animation'
import { getTime, parse } from 'date-fns'

import App from './App.vue'
import router from './router'
import store from './store'
import ComponentRegistration from "./utility/components-registration"

import vuetify from '@/plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'firebase/compat/auth'; //v9
import "@/styles/index.css"
import 'pretty-checkbox/src/pretty-checkbox.scss'



Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(VueNumber)
ComponentRegistration(Vue)

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

console.log(process.env.VUE_APP_VERSION)

async function runner() {
    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App),
    }).$mount('#app')
}



async function userRegisterChcek(userData) {
    console.log("ADLCON V.0.0.3")
    runner()
}


async function getHomeData(userData) {
  // GET HOME DATA
}

if(window.location.hostname === "localhost" || window.location.hostname === "192.168.1.63") {
    const userData = {
        userId: 'U5e8b9c2d7d5c8f74f94bae0505b8fcd3q',
        email: 'aewtippawanm@gmail.com',
        displayName: 'Σ>―(〃PRIMEMIE〃)♡→',
        pictureUrl: 'https://profile.line-scdn.net/0hAIOGbKU1HmZyPgjS-l1hMU57EAsFEBguCl5TAFRrEFZdCAk2SFtRAAA6ElNWBlg0SApYBwQ6EFMP'
    }
    getHomeData(userData)
    userRegisterChcek(userData)
    $cookies.set('LINE_LIFF_DATA', JSON.stringify(userData))
}
else {
    const userProfile = $cookies.get("LINE_LIFF_DATA")
    const path = location.href.split(location.origin)
    const path2 = path[1].split("/?liff.state=")
    if(decodeURIComponent(path2[1]) !== undefined && decodeURIComponent(path2[1]) !== null && decodeURIComponent(path2[1]) !== "undefined" && decodeURIComponent(path2[1]) !== "null") {
        $cookies.set('LINE_LIFF_ORIGIN_PATH', decodeURIComponent(path2[1]))
    }
    if(!userProfile) {
        liff.init({ liffId: process.env.VUE_APP_LINE_LIFF_ID })
            .then(async () => {
                if (liff.isLoggedIn()) {
                    const profile = await liff.getProfile()
                    // const email = liff.getDecodedIDToken().email
                    // profile.email = email || ""
                    // profile.line_liff_token = liff.getIDToken()
                    $cookies.set('LINE_LIFF_DATA', JSON.stringify(profile))
                    getHomeData(profile)
                    userRegisterChcek(profile)
                } else {
                    liff.login()
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    else {
        if (window.location.href.search("liff.state") > -1) {
            $cookies.set('LINE_PATH_DATA', decodeURIComponent(window.location.search))

            const qsParse = queryString.parse(decodeURIComponent(window.location.search));

            // if(qsParse.period && qsParse.stage) {
            //     window.location = `${qsParse["liff.state"]}&period=${qsParse.period}&stage=${qsParse.stage}`;
            // }
            // else {
            //     window.location = `${qsParse["liff.state"]}`;
            // }
            window.location = `${qsParse["liff.state"]}`;

            getHomeData(userProfile)
            userRegisterChcek(userProfile)
        }
        else {
            getHomeData(userProfile)
            userRegisterChcek(userProfile)
        }
    }
}