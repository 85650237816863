<template>
    <div class="content-wrap">
        <div class="header-wrap">
            <div class="page-back" v-if="!hideBackButton" @click="backClick">
                <img src="@/assets/icons/arrow-left.svg" alt="">
            </div>
            <div class="page-title">{{pageTitle}}</div>
      
        </div>
        <div class="logo-title">
            <!-- <img src="@/assets/store/images/logo-1.png" class="w-100" alt=""> -->
        </div>
        <div :class="classes">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { getTime, parse } from 'date-fns'


export default {
    props: {
        hideMenuBar: {
            type: Boolean,
            default: false,
        },
        pageTitle: {
            type: String,
            default: ''
        },
        hideBackButton: {
            type: Boolean,
            default: false,
        },
        backPath: {
            type: String,
            default: '',
        },
        active: {
            type: String,
            default: 'home'
        },
        hideFooterBackground: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const userProfile = this.$cookies.get("LINE_LIFF_DATA")
        const userData = this.$cookies.get("USER_DATA")
   
        return {
            userProfile,
            formData: {},
            display:false
        }
    },
    mounted() {
        this.onLoad()
    },
    computed: {
        classes() {
            return {
                'content': true,
                'content-without-menu': this.hideMenuBar,
                'content-without-bg': this.hideFooterBackground,
            };
        },
        displayMenu() {
            if(this.formData.status === '3' || this.formData.status === '6') {
                return this.memberMenus
            }
            else {
                return this.menus
            }
        }
    },
    methods: {
        async onLoad() {
    

        },
        backClick() {
            if(this.backPath) {
                this.$router.push(this.backPath)
            }
            else {
                this.$router.back()
            }
        }
    },
}
</script>

<style scoped>
@font-face {
    font-family: 'DB Helvethaica X 77 BdCond';
    src: url('../../assets/store/fonts/DBHelvethaicaX-77BdCond.woff2') format('woff2'),
        url('../../assets/store/fonts/DBHelvethaicaX-77BdCond.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DB Helvethaica X 67 MedCond';
    src: url('../../assets/newfont/DBHelvethaicaX-67MedCond.eot');
    src: url('../../assets/newfont/DBHelvethaicaX-67MedCond.eot?#iefix') format('embedded-opentype'),
        url('../../assets/newfont/DBHelvethaicaX-67MedCond.woff2') format('woff2'),
        url('../../assets/newfont/DBHelvethaicaX-67MedCond.woff') format('woff'),
        url('../../assets/newfont/DBHelvethaicaX-67MedCond.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

.btn-w250{
        width: 60%;
}
.content-wrap {
    height: 100%;
    overflow: auto;   
    position: relative;
    box-shadow: 0px 0px 10px grey;
    /* background-image: url('../../assets/images/bg.png'); */
    /* background-image: url(/img/bg.c4154c07.png); */
    /* background-size: 100%;
    background-repeat: no-repeat;
    background-color: #09235B;     */
}
.content {
    /* padding: 44px 0 72px; */
	position: relative;
    /* min-height: calc(var(--vh, 1vh) * 70); */
    display: flex;
    flex-direction: column;
    font-family: 'DB Helvethaica X 77 BdCond';
    /* background: #3363A1; */
    /* background-image: url('../../assets/images/bg.png'); */
}
.content::before {    
    /* content: "";
    background-image: url("../../assets/images/footer-bg.png");
    background-repeat: no-repeat;
    background-size: 144% 253px;
    background-position: bottom 30px center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5; */
}
.content.content-without-bg::before {
    /* background-image: none; */
    
}
.content-without-menu {
    padding-bottom: 6px;
}
.header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0);
    z-index: 100;
    height: 52px;
    border-bottom-right-radius: 24px;
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15); */
    padding: 0 24px;
    display: flex;
    align-items: center;
}
.logo-title{
    height: 175px;
}
.footer-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #002F87;
    z-index: 100;
    height: 72px;
    border-top-left-radius: 24px;
    padding: 0 16px;
}
.page-back {
    width: 20px;
    z-index: 1;
}
.page-title {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    text-align: center;
    padding: 0 42px;
}
.menus {
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-item {
    width: 66px;
    min-width: 66px;
    margin-right: 4px;
    height: 72px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position:  relative;
    padding: 8px 0 2px;
}
.icon-wrap {
    width: 32px;
    height: 32px;
    background-color: transparent;
    border-radius: 100px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-label {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
}
.active-line {
    height: 2px;
    background-color: transparent;
    width: 38px;
    position: absolute;
    bottom: 0;
}
.menu-item.active .icon-wrap {
    background-color: #ffffff;
    border: 1px solid #FF9E18;
}
.menu-item.active .active-line {
    background-color: #FF9E18;
}
.menu-item.active .menu-label {
    color: #FF9E18;
    font-weight: 700;
}
.menu-item:last-child {
    margin-right: 0;
}
.btn-fix-bottom {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -10%);
}
.btn-next {
    text-align: center;
    cursor: pointer;
}
.fc-1 {
    color: #002F87 !important;
}
.fc-2 {
    color: #F0F6FF !important;
}
.fs-22 {
    font-size: 22px;
}
.line-display-img {
        margin-top: -50px;
}
.mg-top-20 {
    margin-top: 20px;
}
.mg-top-15 {
    margin-top: 15px;
}
@media only screen and (max-width: 320px) {
    .menu-item {
        width: 60px;
        min-width: 60px;
        padding: 6px 0 2px;
    }
    .menu-label {
        font-size: 10px;
    }
    .content {
        padding: 0px 0 20px;
    }
    .footer-wrap {
        padding-bottom: 24px;
        height: 96px;
    }
    .line-display-img {
        margin-top: -35px;
    }
    .font-name-res {
           font-size: 22px !important;
    }
}
@media only screen and (min-width: 1200px) {
    .content-wrap{
        width: 100%;
        max-width: 480px;
        margin: 0 auto;
    }
    .line-display-img {
        margin-top: -35px;
    }
    
}
/* iPhone 13 mini */
@media only screen
    and (device-width: 375px)
    and (device-height: 812px)
    and (-webkit-device-pixel-ratio: 3) {
        .content {
            padding: 0px 0 20px;
        }
        .footer-wrap {
            padding-bottom: 24px;
            height: 96px;
        }
        .line-display-img {
        margin-top: -35px;
    }
    .font-name-res {
        font-size: 26px !important;
    }
    }
/* iPhone 13 and iPhone 13 Pro */
@media only screen
    and (device-width: 390px)
    and (device-height: 844px)
    and (-webkit-device-pixel-ratio: 3) {
        .content {
            padding: 0px 0 20px;
        }
        .footer-wrap {
            padding-bottom: 24px;
            height: 96px;
        }
           .font-name-res {
             font-size: 26px !important;
        }
    }
/* iPhone 13 Pro Max */
@media only screen
    and (device-width: 428px)
    and (device-height: 926px)
    and (-webkit-device-pixel-ratio: 3) {
        .content {
            padding: 0px 0 20px;
        }
        .footer-wrap {
            padding-bottom: 24px;
            height: 96px;
        }
    }
/* iPhone 11 and iPhone XR */
@media only screen
    and (device-width: 414px)
    and (device-height: 896px)
    and (-webkit-device-pixel-ratio: 2) {
        .content {
            padding: 0px 0 20px;
        }
        .footer-wrap {
            padding-bottom: 24px;
            height: 96px;
        }
        .line-display-img {
            margin-top: -35px;
        }
    }
 /* iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X */
@media only screen
    and (device-width: 375px)
    and (device-height: 812px)
    and (-webkit-device-pixel-ratio: 3) {
        .content {
            padding: 0px 0 20px;
        }
        .footer-wrap {
            padding-bottom: 24px;
            height: 96px;
        }
        .line-display-img {
            margin-top: -35px;
        }
    }
 /* iPhone 11 Pro Max and iPhone Xs Max */
@media only screen
    and (device-width: 414px)
    and (device-height: 896px)
    and (-webkit-device-pixel-ratio: 3) {
        .content {
            padding: 0px 0 20px;
        }
        .footer-wrap {
            padding-bottom: 24px;
            height: 96px;
        }
        .line-display-img {
            margin-top: -35px;
        }
    }
</style>