import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/main/index.vue')
    },
    {
        path: '/user/term',
        name: 'Term and Condition',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/user/term/Index.vue')
    },
    {
        path: '/user/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/user/home/Index.vue')
    },
    {
        path: '/user/register',
        name: 'Register Form',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/user/register/Form.vue')
    },
    {
        path: '/user/questionnaire',
        name: 'Register questionnaire',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/user/questionnaire/Form.vue')
    },
    {
        path: '/user/coupon-detail',
        name: 'Coupon Detail',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/user/coupon-detail/Index.vue')
    },
    {
        path: '/user/storelist',
        name: 'Store Detail',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/user/store-list/Index.vue')
    },

    // Admin
    {
        path: '/admin/term',
        name: 'Welcome Page',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/term/Index')
    },
    {
        path: '/admin/welcome',
        name: 'Welcome Page',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/welcome/Index')
    },
    {
        path: '/admin/use-coupon',
        name: 'Use Coupon',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/useCoupon/Index')
    },
    {
        path: '/scan-qr',
        name: 'Use Coupon',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/useCoupon/Index')
    },
    {
        path: '/admin/qr',
        name: 'qr',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/scanQR/Index')
    },
    {
        path: '/admin/history',
        name: 'qr',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/history/Index')
    },
    {
        path: '/admin/finish',
        name: 'qr',
        component: () => import(/* webpackChunkName: "Activity" */ '../views/admin/finish/Index')
    },
    {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
