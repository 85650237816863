<template>
    <v-app>
        <router-view/>
    </v-app>
</template>

<script>
export default {
    async mounted() {
        let path = $cookies.get("LINE_LIFF_ORIGIN_PATH")
		if(path !== undefined && path !== null && path !== "undefined" && path !== "null") {
			this.$router.push(path)
			$cookies.remove("LINE_LIFF_ORIGIN_PATH")
        }
    },
}
</script>

<style lang="scss">
</style>
