<template>
    <div :id="element"></div>
</template>

<script>
var Highcharts = require('highcharts')

export default {
    props: ['title', 'series', 'categories', 'element', 'seriesName', 'historyData', 'type'],
    data() {
        return {
        }
    },
    computed: {
        options() {
            return {
                credits: {
                    enabled: false,
                },
                title: {
                    text: ''
                },
                chart: {
                    type: 'line',
                    height: 150,
                },
                xAxis: {
                    categories: this.categories,
                    labels: {
                        style: {
                            color: '#002F87',
                            fontSize: '10px'
                        },
                        rotation: this.type === 'weekly' ? -45 : 0,
                    },
                    tickColor: '#D5DCED',
                    tickWidth: 1,
                    tickLength: 5,
                    tickmarkPlacement: 'on'
                },  
                yAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    },
                    gridLineColor: '#D5DCED',
                    min: 0,
                },
                legend: {
                    enabled: false,
                },
                series: [{
                    name: 'distance',
                    data: this.series,
                    color: '#002F87'
                }],
                tooltip: {
                    enabled: false,
                }
            }
        }
    },
    watch: {
        'series': function () {
            
            this.target = Highcharts.chart(this.$el, this.options)
        }
    },
    mounted() {
        Highcharts.setOptions({
            time: {
                useUTC: false
            }
        })
        this.target = Highcharts.chart(this.$el, this.options)
    },
    beforeDestroy() {
        this.target.destroy()
    },
}
</script>
