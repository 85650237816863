<template>
<div class="wrap">
    <img class="icon" :src="icon" alt="" v-if="icon">
    <input
        v-bind="$attrs"
        :type="type"
        :class="classes"
        :placeholder="placeholder"
        :value="value"
        @input="onInput"
        @keypress="onKeypress"
    >
    <span class="message-error" v-if="error">{{errorMessage}}</span>
</div>
</template>

<script>
export default {
    name: 'base-input',
    props: {
        placeholder: {
            type: String,
            required: false,
        },
        error: {
            type: Boolean,
            required: false,
        },
        errorMessage: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        value: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            required: false,
        }
    },
    computed: {
        classes() {
            return {
                'input': true,
                'input-error': this.error,
                'input-icon': this.icon,
            };
        },
    },
    methods: {
        onInput(event) {
            this.$emit('input', event.target.value)
        },
        onKeypress(event) {
            this.$emit('onKeypress', event)
        }
    },
}
</script>

<style scoped>
.wrap {
    position: relative;
}
.input {
    width: 100%;
    height: 40px;
    box-shadow: 1px 1px 5px solid #265599;
    background-color: #F0F6FF;
    border-radius: 8px;
    font-weight: 700;
    font-size:30px;
    color: #1c4287;
    padding: 0 16px;
    line-height:30px;
    text-indent: 1px;
}
.input:focus {
    outline: none;
}
.input.input-error {
    border: 1px solid #FF3535;
}
.input::placeholder {
    color: #C4C4C4;
    font-weight: 400;
    line-height: 48px;
    text-indent: 1px;
}
.input:disabled {
    border-color: #CCD0D1;
    background-color: #CCD0D1;
}
.message-error {
    color: #FF3535;
    font-family: 'Sarabun', sans-serif;
    font-weight: 400;
    font-size: 12px !important;
    display: block;
    margin-top: 4px;
}
.input-icon {
    padding-left: 56px;
}
.icon {
    position: absolute;
    left: 16px;
    top: 12px;
}
</style>