<template>
    <button
        :class="classes"
        :disabled="disabled"
        @click="onClick"
    >
        {{ label }}
    </button>
</template>

<script>
export default {
    name: 'base-button',
    props: {
        label: {
            type: String,
            required: true,
        },
        outline: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            required: false,
        },
    },
    computed: {
        classes() {
            return {
                'button': true,
                'button-outline': this.outline,
                'button-full': this.full,
                'button-small': this.size === 'small',
            };
        },
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        },
    },
}
</script>

<style scoped>
.button {
    font-weight: 700;
    font-size: 18px !important;
    border: 1px solid #FF6B00;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    color: #ffffff !important;
    background-color: #FF6B00 !important;
    height: 48px !important;
    min-height: 48px !important;
    padding: 0 14px !important;
    letter-spacing: 0;
    text-indent: 0;
    text-transform: none;
    flex: 1;
}
.button:focus {
    box-shadow: none;
    outline: none
}
.button:disabled {
    background-color: #CCD0D1 !important;
    border: 1px solid #CCD0D1 !important;
    color: #ffffff !important;
}
.button-outline {
    border: 1px solid #056839 !important;
    background-color: #ffffff !important;
    color: #056839 !important;
}
.button-full {
    width: 100%;
}
.button-small {
    height: 40px !important;
    min-height: 40px !important;
    font-size: 14px !important;
}
</style>
