// import BaseCheckBox from '../components/base/BaseCheckBox'
// import BaseTextArea from '../components/base/BaseTextArea'
// import BaseDateRange from '../components/base/BaseDateRange'
// import BaseCard from '../components/base/BaseCard'
// import BaseLine from '../components/base/BaseLine'
import LayoutStore from "../components/custom/LayoutStore"
import LayoutUser from "../components/custom/LayoutUser"
import Loading from '../components/custom/Loading'
import LineChart from '../components/custom/LineChart'
import Modal from '../components/custom/Modal'
import BaseBanner from '../components/base/BaseBanner'
import BaseButton from '../components/base/BaseButton'
import BaseInput from '../components/base/BaseInput'
import BaseSelectOption from '../components/base/BaseSelectOption'
import BaseSlide from '../components/base/BaseSlide'
import VueQRCodeComponent from 'vue-qrcode-component'

export default function(Vue) {
    // Vue.component("BaseCard" , BaseCard)
    // Vue.component("BaseImage" , BaseImage)
    // Vue.component("BaseInputIcon" , BaseInputIcon)
    // Vue.component("BaseCheckBox" , BaseCheckBox)
    // Vue.component("BaseTextArea" , BaseTextArea)
    // Vue.component("BaseDateRange" , BaseDateRange)
    // Vue.component("BaseCard" , BaseCard)
    // Vue.component("BaseLine" , BaseLine)
    Vue.component('qr-code', VueQRCodeComponent)
    Vue.component("LayoutStore" , LayoutStore)
    Vue.component("LayoutUser" , LayoutUser)
    Vue.component("Loading" , Loading)
    Vue.component("LineChart" , LineChart)
    Vue.component("Modal" , Modal)
    Vue.component("BaseBanner" , BaseBanner)
    Vue.component("BaseButton" , BaseButton)
    Vue.component("BaseInput" , BaseInput)
    Vue.component("BaseSelectOption" , BaseSelectOption)
    Vue.component("BaseSlide" , BaseSlide)
}
