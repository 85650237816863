<template>
    <banner :options="slideOptions">
        <div
            class="slide"
            v-for="(banner, index) in data"
            :key="index"
        >
            <img v-if="banner.img_url" :src="banner.img_url" :alt="banner.id">
        </div>
    </banner>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
    components: {
        banner: VueAgile 
    },
    data() {
        return {
            slideOptions: {
                navButtons: false,
                autoplay: true,
                autoplaySpeed: 5000,
            },
        }
    },
}
</script>

<style scoped>
.slide {
}
.slide img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
}

</style>