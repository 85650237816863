<template>
    <v-dialog
        max-width="600"
        :value="visible"
        :persistent="true"
        overlay-opacity="0.8"
    >
        <slot />
    </v-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    methods: {
        onSubmit() {
            this.$emit('onSubmit')
        }
    },
}
</script>